<template>
  <esmp-modal
    v-model="isShowed"
    title="Редактирование списка подключений к БД"
    class="edit-connections-modal"
  >
    <div class="edit-connections-modal__list" v-if="databases">
      <div
        v-for="(item, index) in databases"
        :key="`database-${index}`"
        class="edit-connections-modal__item"
      >
        <div class="edit-connections-modal__item-name">
          {{ item.name }}
        </div>
        <div class="edit-connections-modal__item-actions">
          <esmp-button
            size="small"
            icon="edit"
            view="function"
            @click="$emit('edit-connection', item)"
          />
          <esmp-button
            size="small"
            icon="trash"
            view="function"
            @click="$emit('remove-connection', item)"
          />
        </div>
      </div>
    </div>
    <template #footer>
      <esmp-button view="outline" @click="isShowed = false">
        Закрыть
      </esmp-button>
    </template>
  </esmp-modal>
</template>

<script>

export default {
  name: 'ModalEditConnections',
  model: {
    prop: 'showed',
    event: 'input',
  },
  props: {
    showed: {
      type: Boolean,
      required: true,
    },
    databases: {
      type: Array,
      default: null,
    },
  },
  computed: {
    isShowed: {
      get() {
        return this.showed;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style lang="scss">
.edit-connections-modal {
  &__item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $color-black-op-10;
    padding: calc(#{$base-gutter} / 2) 0;
  }
  &__item-name {
    flex-grow: 1;
    margin-right: $base-gutter;
  }
  &__item-actions {
    display: flex;
    flex-shrink: 0;
  }
}
</style>
